<template>
    <q-layout>
        <!-- TOP / BRAND -->
        <!-- navbar -->
        <navbar></navbar>

        <!----------------------->
        <!-- PAGE SUB HEADER -->
        <!-- #1 -->
        <div v-if="id == 1">
            <div class="bg-cover bg-nature-drop-water bg-center">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">THE HOMEMADE CARROT ANTI-WRINKLE MASK</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <q-img class="img-smooth-zoom" fit="cover" ratio="1" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-masque-carotte.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">THIS HOMEMADE ANTI-WRINKLE MASK WITH CARROT WILL BRIGHTEN YOUR COMPLEXION !</h1>
                                    <p class="text-mineralgreen text-base mt-3">
                                        Beta-carotene (which gives it its orange color) and vitamin C revitalize the skin and make it look good instantly! They boost your complexion, reboost your skin and give it a boost.
                                    </p>
                                    <p class="text-mineralgreen text-base mt-3">
                                        Choose your vegetables carefully! The more orange the carrot, the more beta carotene it contains, which is a precursor of vitamin A with highly antioxidant properties, thus anti-aging. It is therefore important to choose your vegetables for their quality, preferably organic.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-2">The ingredients</h1>
                                    <p class="text-mineralgreen text-base">
                                        &middot; 1 half carrot<br>
                                        &middot; 1 half carrot<br>
                                        &middot; 1/2 tbsp. olive oil<br>
                                        &middot; A few drops of lemon juice<br>
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 pr-5 pl-5 pt-5 pb-0">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica">Preparation</h1>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <p class="text-mineralgreen text-base">
                                        Steam the carrot after peeling it. Mash it with a fork and add the other Ingredients to the puree. Let it cool and apply 20 minutes on your cleansed face.
                                    </p>
                                    <p class="text-mineralgreen text-base">
                                        Rinse with clear water. You can make this homemade mask once a week.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <p class="text-mineralgreen text-base">
                                        A small variation of this anti-wrinkle mask. Very often, when we make cakes (the famous chocolate fondant...hum), we don't know what to do with our leftover egg whites. You can then use them to make a very powerful homemade face mask!
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #2 -->
        <div v-if="id == 2">
            <div class="bg-cover bg-nature-drop-water bg-center">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">DRY LIPS IN HARMATTAN WEATHER ? WHAT TO DO?</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <q-img class="img-smooth-zoom" fit="cover" ratio="1" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-levres-seches.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">HERE ARE SOME SIMPLE NATURAL METHODS TO TREAT CHAPPED LIPS</h1>
                                    <p class="text-mineralgreen text-base mt-3">
                                        During the harmattan season, lips are very exposed and are subjected to harsh tests. When they  are chapped, they are dehydrated and irritated. Cracked, they are sometimes painful to the point that it is not pleasant to smile. Fortunately, there are natural and effective ways to treat chapped lips and a simple solution to prevent this problem.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <p class="text-mineralgreen text-base mb-5">
                                        The ingredients of natural origin are very effective to keep your mouth well hydrated and protect your lips without damaging them during the harmattan. Here are a few easy tips:
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica">Tip 1: Start with an ultra-gentle scrub</h1>
                                    <p class="text-mineralgreen text-base">
                                        Exfoliate chapped lips with honey and a little powdered sugar. Remain to rub very gently with a soft toothbrush moistened before rinsing and wiping. The lips are rid of small skins, ready to receive a care.
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica">Tip 2: Honey for a delicious healing balm</h1>
                                    <p class="text-mineralgreen text-base">
                                        To naturally heal chapped lips, it is highly recommended to apply a highly repairing mask several times a day: honey. Applied to the lips in a generous layer, it needs to be left on for fifteen minutes before being removed with a soft, lint-free cotton pad soaked in warm water. Dab the lips to dry them without irritating them. Honey has a remarkable healing power. It thus makes it  possible to fill the small so painful cracks. The shea butter for a repairing hydration.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <p class="text-mineralgreen text-base">
                                        Derived from the eponymous nut, shea butter is reputed to moisturize, soften and repair. Packed with vitamins A and E, it is the ally of chapped lips. The ideal is to coat damaged lips with a thick layer of shea butter before going to bed and then keep this life-saving mask on until the morning. From the first application, the repairing functions of the shea butter will begin to take effect. This repairing mask for the lips can also be applied during the day.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica">Tip 3: Baking soda and rose floral water</h1>
                                    <p class="text-mineralgreen text-base">
                                        Mix baking soda and rose water until you have a paste. Apply it to the area around your eyes and leave on for 15 minutes before rinsing with clear water. Emollient and whitening, the bicarbonate will help soften and lighten the skin. As for the rose water, it will come to decongest and tone it. You can replace the rose water with cornflower water, which is also very effective in the treatment of puffy and ringed eyes.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #3 -->
        <div v-if="id == 3">
            <div class="bg-cover bg-nature-drop-water bg-center">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">Natural tips to reduce dark circles</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <q-img class="img-smooth-zoom" fit="cover" ratio="1" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-cernes.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">Dark circles are never welcome on face! Act now!</h1>
                                    <p class="text-mineralgreen text-base mt-3">
                                        Whether they are hereditary, caused by fatigue, stress or hormonal imbalance, dark circles are never welcome on a face! In addition to looking sick and tired, they are particularly tough and are difficult to dislodge. However, with a little patience and a few home remedies, it is quite possible to reduce them, or even to eliminate them. Right away, here are 4 natural tips against dark circles!
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica">Tip 1: Two cold spoons</h1>
                                    <p class="text-mineralgreen text-base">
                                        Place two teaspoons in the fridge for 15 to 20 minutes. Then apply them
                                        on your eyes for a maximum of 10 minutes: the cold will activate blood circulation and
                                        awaken the gaze. Be careful, however, not to use this trick too often.
                                        Indeed, the blood vessels around the eye are very fragile and could suffer from it.
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">Tip 2: Cucumber or potato slices</h1>
                                    <p class="text-mineralgreen text-base">
                                        Cut two slices of cucumber or potato and place one on each
                                         eye before going to bed. Repeat every night for several weeks
                                         making sure to keep the vegetables cool for more efficiency.
                                    </p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica">Tip 3: Bicarbonate and rose floral water</h1>
                                    <p class="text-mineralgreen text-base">
                                        Mix baking soda and rose water until you get a paste. Apply to
                                        around your eyes and leave on for 15 minutes before rinsing with clear water. emollient and
                                        whitening, baking soda will soften and lighten the skin. As for rose water,
                                        it will decongest and tone it. You can replace rose water with cornflower water, also very effective in the treatment of puffy and dark circles.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica">Tip 4: Homemade honey lotion</h1>
                                    <p class="text-mineralgreen text-base">
                                        Moisturizing, anti-inflammatory and soothing, honey is the ideal food to reduce stubborn dark circles.
                                        To use, dilute 1 tbsp. c. in half a glass of lukewarm water. Soak two cotton pads or compresses
                                        of this solution and put them on your eyes for 5 to 10 minutes. Then rinse with clean water and
                                        repeat the operation for several days.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- #4 -->
        <div v-if="id == 4">
            <div class="bg-cover bg-nature-drop-water bg-center">
                <div class="container mx-auto">
                    <div class="p-5 row">
                        <div class="col-12 flex flex-col items-start">
                            <h1 class="text-white text-3xl font-black font-epigrafica mt-2 uppercase ml-2">THE MULTIPLE BENEFITS OF SESAME OIL'S</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div class="container mx-auto">
                    <div class="py-14 bg-cover row">
                        <div class="container mx-auto">
                            <div class="row">
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <q-img class="img-smooth-zoom" fit="cover" ratio="1" src="@/assets/media/experts-counselings/ibc-international-bio-cosmetics-conseil-experts-huile-de-sesame.jpg" />
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <h1 class="text-mineralgreen text-4xl font-black font-epigrafica mt-2 uppercase">NUTRIENTS MAKE IT AN ALLY BOTH ON THE STOVE AND IN THE BATHROOM</h1>
                                    <p class="text-mineralgreen text-base mt-3">
                                        Sesame oil penetrates quickly under the skin and nourishes it deeply. It makes an excellent massage oil that keeps the skin supple and elastic, protects it from microbes and accelerates  healing.
                                    </p>
                                </div>
                                <div class="col-12 col-sm-12 col-md-4 p-5">
                                    <p class="text-mineralgreen text-base">
                                        To give yourself a moment of well-being before going to bed, apply a teaspoon of sesame oil on your feet, massage, put on socks so as not to dirty your sheets and enjoy your night.
                                    </p>
                                    <h1 class="text-mineralgreen text-xl font-black font-epigrafica mt-5">The benefits of sesame oil for the hair</h1>
                                    <p class="text-mineralgreen text-base">
                                        To make your hair shine without using chemicals, choose sesame oil. Its essential nutrients will protect the hair fiber and scalp, thus limiting the appearance of dandruff and hair loss.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- FOOTER CONTENT -->
        <!-- footer -->
        <footerbar></footerbar>
        
    </q-layout>
</template>

<script>
import navbar from "@/views/shared/en/Navbar"
import footerbar from "@/views/shared/en/Footer"
import { useMeta } from "quasar"

export default {
    name: "ExpertCounseling",
    components: { navbar, footerbar },
    data() {
        return {}
    },
    computed: {
        id () { return this.$route.params.id },
    },
    created () {
        useMeta({
            title: 'International Bio Cosmetics | Conseil #' + this.$route.params.id, // sets document title
        })
    }
};
</script>